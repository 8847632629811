import { dew as _anyDew } from "./lib/any";
import { dew as _parseDew } from "./lib/parse";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.matches = matches;
  exports.selectAll = selectAll;
  exports.select = select;

  var any = _anyDew();

  var parse = _parseDew();

  function matches(selector, node) {
    return Boolean(any(parse(selector), node, {
      one: true,
      shallow: true
    })[0]);
  }

  function select(selector, node) {
    return any(parse(selector), node, {
      one: true
    })[0] || null;
  }

  function selectAll(selector, node) {
    return any(parse(selector), node, {});
  }

  return exports;
}