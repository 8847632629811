var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = match;

  function match(query, node) {
    return query.tagName === "*" || query.tagName === node.type;
  }

  return exports;
}