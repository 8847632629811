import { dew as _nameDew } from "./name";
import { dew as _attributeDew } from "./attribute";
import { dew as _pseudoDew } from "./pseudo";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = test;

  var name = _nameDew();

  var attributes = _attributeDew();

  var pseudos = _pseudoDew();

  function test(query, node, index, parent, state) {
    if (query.id) {
      throw new Error("Invalid selector: id");
    }

    if (query.classNames) {
      throw new Error("Invalid selector: class");
    }

    return Boolean(node && (!query.tagName || name(query, node)) && (!query.attrs || attributes(query, node)) && (!query.pseudos || pseudos(query, node, index, parent, state)));
  }

  return exports;
}